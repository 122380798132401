/*eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
export default {
  name: 'approve-request-popup',
  components: {},
  props: ['joinUserINFO'],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    closeJoinRecord() {
      bus.$emit('joinRequestRecordModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    confirmJoinRecord(){
      if(this.joinUserINFO.support_group){
        let data = {
          active:true,
          user:this.joinUserINFO.user.id,
          support_group:this.joinUserINFO.support_group
        }
        this.$store.dispatch('updateSGMemberFunc', data).then((res) => {
          toast.success(res.data.msg);
          this.getSgListFun();
          this.closeJoinRecord();
        }).catch((err) => {
          toast.error(err.data.user_msg || err.data.msg);
        })
      }else{
        let data = {
          active:true,
          user:this.joinUserINFO.user.id,
          ngo_form:this.joinUserINFO.ngo_form
        }
        this.$store.dispatch('updateNgoMemberFunc', data).then((res) => {
          toast.success(res.data.msg);
          this.getngoListFun();
          this.closeJoinRecord();
        }).catch((err) => {
          toast.error(err.data.user_msg || err.data.msg);
        })
      }
    },
    getSgListFun() {
      this.$store.dispatch('getSGInfoNByIdApiFun', {id:this.joinUserINFO.support_group})
    },
    getngoListFun() {
      this.$store.dispatch('getNGOInfoNByIdApiFun', {id:this.joinUserINFO.ngo_form})
    },
  }
}