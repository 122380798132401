import { render, staticRenderFns } from "./approve-request-popup.html?vue&type=template&id=329a7946&scoped=true&external"
import script from "./approve-request-popup.js?vue&type=script&lang=js&external"
export * from "./approve-request-popup.js?vue&type=script&lang=js&external"
import style0 from "./approve-request-popup.scss?vue&type=style&index=0&id=329a7946&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329a7946",
  null
  
)

export default component.exports