/*eslint-disable */
import {
  mapGetters
} from 'vuex';
import {bus} from '@/main';  
import countries from '@/services/country.json'
import ngoJoinRequestPopup from '@/components/private/approve-request-popup';
export default {
  name: 'support-group-info-page',
  components: {
    'join-request-popup' : ngoJoinRequestPopup
  },
  props: [],
  data () {
    return {
      ngoInfoData:null,
      ngoInfoDataResults:null,
      countryList:[],
      joinRequestPopupState:false,
      joinUserInfo:''
    }
  },
  computed: {
    ...mapGetters([
      'getSGInfoGetters'
    ]),
    getNgoInfoDataFun(){
     this.ngoInfoData = this.$store.getters.getSGInfoGetters.data
     return;
    },
    setInitialStatesOfNGoFun() {
      if (this.ngoInfoData) {
        this.ngoInfoDataResults = this.ngoInfoData.members
      }
      return;
    },
  },
  mounted(){
    this.getNgoInfoDataFun
    this.setInitialStatesOfNGoFun 
    bus.$on('joinRequestRecordModalBus', (data) => {
      this.joinRequestPopupState = data
    });
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods: {
    joinRequestPopupOpenMethod(info){
      this.joinUserInfo = info
      document.body.classList.add('popup_open_body')
      this.joinRequestPopupState = true
    },
    getCountryNameFun(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
  },
  watch:{
    getNgoInfoDataFun(){
      return this.$store.getters.getSGInfoGetters;
    },
    setInitialStatesOfNGoFun(){
      if (this.ngoInfoData) {
        this.ngoInfoDataResults =  this.ngoInfoData.members
      }
      return;
    },
  }
}